/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, Plyr) => {
        console.log('solutions');
        let xhr = null;

        /*
        |
        | Premiere instance
        |--------------------
        */
        var firstTaxo = $($('.filter-item')[0]).data().taxoid;
        loadProductsAjax(firstTaxo, 'products')
        $($('.filter-item')[0]).addClass('active')

        /*
        |
        | Clique sur les filtres
        |------------------------- 
        */
        $('.filter-item').on('click', function(){

			$('.filter-item').each(function(){
				$(this).removeClass('active');
			})

			$(this).addClass('active');

            var taxoId = $(this).data().taxoid
            loadProductsAjax(taxoId, 'products');
        })

        /*
        |
        | Filtre en Ajax
        |------------------
        */
        function loadProductsAjax(taxo, post) {
            if (xhr !== null) {
                xhr.abort()
            }

            //get language
            let currentLang = document.querySelector('[data-lang]').attributes['data-lang'].value;

			//Ajoute le loader
			document.querySelector('.products').innerHTML = "";
			document.querySelector('.loader').style.display = "block";

            const route = `/ajax/products/category/${post}/`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                data: {
                    'taxoId': taxo,
                    'post': post,
                    'lang' : currentLang,
                },
                success: (response, status) => {


					//Rempli le DOM
                    document.querySelector('.products').innerHTML = response;

					//Crée les Sliders
                    setSliders();

					//Crée les vidéos
					setVideos();


					//Enlève le loader
					document.querySelector('.loader').style.display = "none";

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }


        /*
        |
        | Sliders
        |------------------
        */
        function setSliders(){
            document.querySelectorAll('.product-item').forEach((item, index) => {

                var $slider = $(item).find('.swiper-container');

                new Swiper($slider, {
                    speed: 400,
                    spaceBetween: 100,
                    pagination: {
                        el: '.swiper-pagination-' + index,
                        type: 'bullets',
                        clickable: true,
                      },
                });
            })
        }

		/*
		|
		| Vidéos
		|------------------
		*/
		function setVideos(){
			document.querySelectorAll('.product-item').forEach((item) => {
				var video = item.querySelector('#player');
				const player = new Plyr(video);
			})
		}
    }
}

