/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";

export default {
    init: (app) => {
        console.log('customers');

        /*
        |
        | Animation des liens
        |----------------------
        */

        var customerCards = document.querySelectorAll('.customer-card');
        
        customerCards.forEach(element =>{

            let link = element.querySelector('.customer-btn');

            var timelineHover = new TimelineMax({paused: true});
            timelineHover.from(link, .3, {y: 10, autoAlpha: 0});

            element.addEventListener('mouseenter', function(){
                timelineHover.play();
            })

            element.addEventListener('mouseleave', function () { 
                timelineHover.reverse();
            })

        })


    }
}
