/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Kira, ScrollMagic, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $burger = $('.burger'),
            $navResponsive = $('.responsive'),
            $scrollDown = $('.anchor-down'),
            $menuResponsive = $('nav.responsive')
            ;


		/*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
        |
        | Menu responsive
        |------------------
        */
        var open = false;
        $burger.on('click', function () {
            if (open == false) {
                $navResponsive.addClass('open');
                $burger.addClass('open');
                open = true;
            } else {
                $navResponsive.removeClass('open');
                $burger.removeClass('open');
                open = false;
            }
        });

        var arrowMenu = document.querySelectorAll('.arrow-menu');
        arrowMenu.forEach(element => {
            var navDrop = $(element).closest('.menu-item').find('.nav-drop');

            var tlDrop = new TimelineMax({ paused: true });
            tlDrop.from(navDrop, .3, { height: 0 }, "start");
            tlDrop.to(element, .3, { rotation: -180 }, "start");

            let open = false;

            element.addEventListener('click', function () {
                if (!open) {
                    tlDrop.play();
                    open = true;
                } else {
                    tlDrop.reverse();
                    open = false;
                }
            })
        })


        var $menuItems = $('.responsive .menu-item-has-children > a');
        $menuItems.on('click', function (event) {
            const $menuItem = $(this);

            event.preventDefault();

            $menuItems.not($menuItem).next().slideUp();
            $menuItem.next().slideToggle();

        })



        /*
        |
        | Anchor
        |--------
        */
        $("a[href*='#']:not([href='#'])").click(function () {
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
                if (anchor.length) {
                    $("html, body").animate({ scrollTop: anchor.offset().top }, 1500);
                    if ($menuResponsive.hasClass('open')) {
                        $('.responsive').removeClass('open');
                        $('.burger').removeClass('open');
                    }
                }
            }
        });


        /*
        |
        | Sliders
        |----------
        */
        var slider = document.querySelector('.slider-links .swiper-container');

        //init slider
        var swiperLinks = new Swiper(slider, {
            speed: 1000,
            slidesPerView: 'auto',
            centeredSlides: true,
            breakpoints: {
                992: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                    centeredSlides: false,
                    speed: 100,
                },
            },
            navigation: {
                nextEl: '.arrow-next',
                prevEl: '.arrow-prev'
            },
            on: {
                'init': function () {
                    let activeSlide = this.slides[this.activeIndex];

                    let activeOverlay = activeSlide.querySelector('.overlay');
                    let overlay = document.querySelectorAll('.overlay');

                    TweenLite.set(activeOverlay, { autoAlpha: 0 });
                    TweenLite.set(overlay, { skewX: 40, skewType: 'simple', force3D: true });
                }
            }

        });

        //transition start
        swiperLinks.on('slideChangeTransitionStart', function (e) {

            //Get slides
            let activeSlide = swiperLinks.slides[swiperLinks.activeIndex];
            let previousSlide = swiperLinks.slides[swiperLinks.previousIndex];

            //Get overlays to Skew
            let overlay = activeSlide.querySelector('.overlay');
            let previousOverlay = previousSlide.querySelector('.overlay');

            var timelinePrev = new TimelineMax({ paused: true });

            timelinePrev.fromTo(overlay, 0.7, { skewX: 40, skewType: 'simple', force3D: true }, { skewX: 40, skewType: 'simple', force3D: true, ease: Expo.easeInOut }, 'start')
            timelinePrev.to(overlay, .6, { x: '100%' }, 'start')
            timelinePrev.set(overlay, { autoAlpha: 0 })

            timelinePrev.fromTo(previousOverlay, 0.7, { skewX: 0, skewType: 'simple', force3D: true }, { skewX: 40, skewType: 'simple', force3D: true, transformOrigin: "0%", ease: Expo.easeInOut }, 'start')
            timelinePrev.fromTo(previousOverlay, .4, { x: '100%' }, { x: "0%" }, 'start')
            timelinePrev.set(previousOverlay, { autoAlpha: 1 })

            timelinePrev.play()

        });




        /*
		|
		| Cookie Manager
		|-----------------
		*/
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     }
        // });

        /*
		|
		| Kira
		|-------
        */
        const kira = new Kira({
            dependencies: {
                scrollmagic: ScrollMagic
            }
        })
    }
}
