/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import scrollOverflow from 'fullpage.js/vendors/scrolloverflow.min.js'; //désolé
const ScrollTo = require('gsap/ScrollToPlugin')

import Swiper from 'swiper/dist/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import ScrollMagic from 'scrollmagic';
// import ScrollTo from "gsap/ScrollToPlugin";
import Plyr from 'plyr';
import fullpage from 'fullpage.js';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from './components/cookie-manager';
import Kira from './components/kira.js';
import MaterializeForm from './components/materialize-form.js';

/*
|
| Importing Utils
|-------------------
*/
import './utils/fa';
import Router from './utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from './components/global.js';
import main from './pages/main.js';
import home from './pages/home.js';
import customers from './pages/customers-archive.js';
import customer from './pages/customer-single.js';
import solutions from './pages/solutions.js';
import contact from './pages/contact';
import news from './pages/news-archive';
import globalCoverage from './pages/global-coverage';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': main,
        'dependencies': [app, CookieManager, Kira, ScrollMagic, Swiper]
    },
    {
        'file': solutions,
        'dependencies': [app, Swiper, Plyr],
        'resolve': '#page-solutions'
    },
    {
        'file': globalCoverage,
        'dependencies': [app],
        'resolve': '#page-global-coverage'
    },
    {
        'file': customers,
        'dependencies': [app],
        'resolve': '#page-customers'
    },
    {
        'file': customer,
        'dependencies': [app, Swiper],
        'resolve': '#page-customer-single'
    },
    {
        'file': home,
        'dependencies': [app, Swiper, Plyr, fullpage, TweenMax, scrollOverflow, MaterializeForm],
        'resolve': '#page-home'
    },
    {
        'file': contact,
        'dependencies': [app, MaterializeForm],
        'resolve': '#page-contact'
    },
    {
        'file': news,
        'dependencies': [app],
        'resolve': '#page-news-archive'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

