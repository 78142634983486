/*** IMPORTS FROM imports-loader ***/
var define = false;

import { library, dom } from '@fortawesome/fontawesome-svg-core/index';

import { 	faWordpress,
			faTwitter,
			faLinkedinIn, 
} from '@fortawesome/free-brands-svg-icons'; // prefix: fab

import { 
			faFire as fasFaFire,
			faChevronDown as fasChevronDown,
			faChevronUp as fasChevronUp,
		} from '@fortawesome/pro-solid-svg-icons'; // prefix: fas

import { faFire as farFaFire } from '@fortawesome/pro-regular-svg-icons'; // prefix: far
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons'; // prefix: fal

library.add(
    faWordpress,
    falTimes,
    farFaFire, 
    fasFaFire,

    fasChevronDown,
    fasChevronUp,

    faTwitter,
	faLinkedinIn,
)
dom.watch();
