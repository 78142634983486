/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map')
        ;


        /*
		|
		| ScrollTo
		|----------------
		|
        */
       document.querySelector('.btn-scroll').addEventListener('click', function(e){
            e.preventDefault();
            TweenLite.to($(window), .5, {scrollTo:{y:$("#form").offset().top - 170}});
        }) 


        /*
		|
		| initGoogleMap
		|----------------
		|
		*/
        $.each($map, function () {
            var $map = $(this);

            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 16
            });
        });


        new MaterializeForm($('.contact-form'), {
            selectors: {
                group: '.gfield',
                label: '.gfield_label',
                input: '.ginput_container_text input, .ginput_container_email input, .ginput_container_phone input, .ginput_container_textarea input'
            },
            labelEffect: {
                duration: 0.3, 
                ease: Power2.easeOut
            }
        });

    }
}
