/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        console.log('customer single');

        var swiperContainer = document.querySelector('.customer-images .swiper-container');

        var swiperImages = new Swiper(swiperContainer, {
            speed: 400,
            spaceBetween: 30,
            centeredSlides: true,
            slidesPerView: 2,
            breakpoints: {
                992: {
                    spaceBetween: 10,
                    slidesPerView: 1.2,
                },
            },
            navigation: {
                nextEl: '.slider-button-next',
                prevEl: '.slider-button-prev',
            },
        })

    }
}
