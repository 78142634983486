/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {


        console.log('coverage')

        var markers = [];

        var map;

        // Fonction d'initialisation de la carte
        function initMap() {
            map = L.map('map', {
                zoom: 3,
                scrollWheelZoom: false,
                center: [48,2],
                zoomAnimation: true,
            });


            var gl = L.mapboxGL({
                attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
                accessToken: 'not-needed',
                style: 'https://api.maptiler.com/maps/df150a78-6312-4825-9eff-d2d564003ccc/style.json?key=lRhFU6OAXYmYR9jo4vfi'
            }).addTo(map);

            $('.implantation').each(function(){

                let thisId = $(this).data().id;
                let lat = $(this).data().lat;
                let lng = $(this).data().lng
                let img = $(this).data().img

                
                //timeline de la popup
                var timelinePopUp = new TimelineMax({paused: true});
                timelinePopUp.to("[data-id='" + thisId + "']", .3, { x: 0});
                timelinePopUp.staggerFrom($("[data-id='" + thisId + "']").find('.item-show'), .4, {autoAlpha: 0, y: 20}, .3, '-=.1')


                //icone du marker en fonction de la categorie
                var myIcon = L.icon({
                    iconUrl: img,
                    iconSize: [15, 15],
                });


                //Création d'un nouveau marker
                var mark = L.marker([
                    parseFloat(lat),
                    parseFloat(lng)
                ],
                {
                    icon: myIcon
                }
                ).addTo(map);


                //création d'un tableau de marker
                markers.push(mark);


                //click sur le marker
                mark.on('click', function(){
                    timelinePopUp.play();

                    console.log()
                    let lat = mark.getLatLng().lat
                    let lng = mark.getLatLng().lng

                    openPopUp(lat, lng);
                });

                
                //click sur le close de la popup
                $('.close').on('click', function () {
                    
                    var group = new L.featureGroup(markers);
                    map.fitBounds(group.getBounds());

                    timelinePopUp.reverse();
                })
            })
            
        }

        function openPopUp(lat, lng){

            //set zoom on point
            map.setView([lat, lng], 13);
        }


        //init la map quand le dom est chargé
        window.onload = function () {
            initMap();
        };

        
    }
}
