/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        console.log('news');
        let xhr = null;


        /*
        |
        | Clique sur les filtres
        |-------------------------
        */
        $('.filter-item').on('click', function () {

            $('.filter-item').each(function () {
                $(this).removeClass('active');
            })

            $(this).addClass('active');

            var taxoId = $(this).data().taxoid
            loadProductsAjax(taxoId, 'post');
        })

        /*
        |
        | Filtre en Ajax
        |------------------
        */
        function loadProductsAjax(taxo, post) {
            if (xhr !== null) {
                xhr.abort()
            }

            //get language
            let currentLang = document.querySelector('[data-lang]').attributes['data-lang'].value;

            //Ajoute le loader
            document.querySelector('.articles').innerHTML = "";
            document.querySelector('.loader').style.display = "block";

            const route = `/ajax/news/category/${post}/`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                data: {
                    'taxoId': taxo,
                    'post': post,
                    'lang' : currentLang,
                },
                success: (response, status) => {


                    //Rempli le DOM
                    document.querySelector('.articles').innerHTML = response;


                    //Enlève le loader
                    document.querySelector('.loader').style.display = "none";

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}

