/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, Plyr, fullpage, TweenMax, scrollOverflow, MaterializeForm) => {
		app.dump('home.js');

		/*
		|
		| Constants
		|-----------
		*/
		new fullpage('#page-home', {

			fitToSection: false,

			navigation: true,
			navigationPosition: 'right',
			continuousVertical: true,
			autoScrolling: false,
			verticalCentered: true,

			onLeave: function (origin, destination, direction) {

				//Change la couleur de la pagination
				if ($(destination.item).hasClass('bg-blue')) {
					$('#fp-nav').addClass('white');
				} else {
					$('#fp-nav').removeClass('white');
				}

				var header = document.querySelector('.header');

				var headerTlDown = new TimelineMax({ paused: true });
				headerTlDown.to(header, .6, { top: '-100%' });

				var headerTlUp = new TimelineMax({ paused: true });
				headerTlUp.to(header, .6, { top: '0' }, 'start');
				headerTlUp.to($('.header-home-to-hide'), .6, { display: 'none' }, 'start')
				headerTlUp.to($('.desktop'), .6, { 'margin-top': '0' }, 'start')

				var headerTlTop = new TimelineMax({ paused: true });
				headerTlTop.to($('.header-home-to-hide'), .6, { display: 'flex' }, 'start')
				headerTlTop.to($('.desktop'), .6, { 'margin-top': '40px' }, 'start')

				if (direction == "down") {
					headerTlDown.play();
				} else {
					headerTlTop.seek(0);
					headerTlUp.play();
					if (destination.index == 0) {
						headerTlTop.play();
					}
				}
			}
		});


		/*
		|
		| Swiper
		|-----------
		*/
		var fullSwiper = new Swiper('.full-swiper', {
			slidesPerView: 1,
			init: true,
			loop: true,
			speed: 1000,
			slideToClickedSlide: true,
			autoplay: {
				delay: 3000,
			},
			breakpoints: {
				640: {
					slidesPerView: 1,
				},
				320: {
					slidesPerView: 2,
				}
			},
			pagination: {
				el: '.full-swiper .swiper-pagination',
				clickable: true
			},
		});


		fullSwiper.on('init', function () {
			var activeSlide = $('.full-swiper .swiper-slide-active').data('slider-image');
			// je récupère la slide active
			$('.txt-swiper .swiper-slide').each(function () {
				var activeDescription = $(this).data('slider-description');
				// Je récupère la description en corrélation avec la slide active
				if (activeSlide == activeDescription) {
					$(this).show();
				}
			})
		});

		fullSwiper.on('slideChangeTransitionStart', function () {
			var activeSlide = $('.full-swiper .swiper-slide-active').data('slider-image');
			// je récupère la slide active
			$('.txt-swiper .swiper-slide').each(function () {
				var activeDescription = $(this).data('slider-description');
				// Je récupère la description en corrélation avec la slide active
				if (activeSlide == activeDescription) {
					$(this).show();
				} else {
					$(this).hide();
				}
			})
		})

		var referencesSwiper = new Swiper('.references-swiper', {

			slidesPerView: 8,
			spaceBetween: 30,
			loop: true,
			speed: 800,
			autoplay: {
				delay: 3000,
			},

			breakpoints: {
				1450: {
					slidesPerView: 5,
				},
				1200: {
					slidesPerView: 4,
				},
				991: {
					slidesPerView: 3,
				},
				575: {
					slidesPerView: 2,
					spaceBetween: 10,
				},
			}
		});

		const controls = '<button type="button" class="plyr__control plyr__control--overlaid plyr__tab-focus" data-plyr="play-large" aria-label="play-large"><svg role="presentation" focusable="false"><use xlink:href="#plyr-play"></use></svg><span class="plyr__sr-only">Play</span></button>';


		const player = new Plyr('#player');
		player.on('play', function (event) {
			$(this).addClass('plyr--init-play');
		});


		/*
		|
		| Accordeon key benefits
		|-------------------------
		*/

		var $containers = $('.key-benefits .item-container');

		$containers.on('click', function () {
			const $container = $(this);
			const $svg = $(this).find('svg');

			$containers.not($container).find('.item-text').slideUp();
			$containers.not($container).find('svg').removeClass('fa-chevron-up').addClass('fa-chevron-down');

			$container.find('.item-text').slideToggle();

			if ($svg.hasClass('fa-chevron-down')) {
				$container.find('svg').removeClass('fa-chevron-down').addClass('fa-chevron-up');
			} else {
				$container.find('svg').removeClass('fa-chevron-up').addClass('fa-chevron-down');
			}

		})


		new MaterializeForm($('.contact-form'), {
			selectors: {
				group: '.gfield',
				label: '.gfield_label',
				input: '.ginput_container_text input, .ginput_container_email input, .ginput_container_phone input, .ginput_container_textarea input'
			},
			labelEffect: {
				duration: 0.3,
				ease: Power2.easeOut
			}
		});

	}
}

